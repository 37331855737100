import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import engravingImgMobi from '../../images/engraving-mobi.jpg'
import engravingImg from '../../images/engraving.jpg'
import { getSelectedCity } from '../../store/app/selectors'
import Popup from '../popups/popup/Popup'

import styles from './Engraving.module.scss'

const links = {
  524901: {
    name: 'Москва',
    link: 'https://gravsport.com/',
    dateExpired: '2025-04-29',
    erid: '2Vtzqvb3TVx',
  },
  520555: {
    name: 'Нижний Новгород',
    link: 'https://gravsport.com/',
    dateExpired: '2025-06-23',
    erid: '2VtzqxCU2BQ',
  },
  498817: {
    name: 'Санкт-Петербург',
    link: 'https://gravsport.com/',
    dateExpired: '2025-08-25',
    erid: '2VtzqxCbkFp',
  },
  551487: {
    name: 'Казань',
    link: 'https://gravsport.com/',
    dateExpired: '2025-07-14',
    erid: '2VtzqxcDW6a',
  },
  1496747: {
    name: 'Новосибирск',
    link: 'https://gravsport.com/',
    dateExpired: '2025-07-07',
    erid: '2Vtzqw6jE2S',
  },
  1508291: {
    name: 'Челябинск',
    link: 'https://gravsport.com/',
    dateExpired: '2025-07-28',
    erid: '2VtzqxNtd1G',
  },
  480562: {
    name: 'Тула',
    link: 'https://gravsport.com/',
    dateExpired: '2025-09-01',
    erid: '2VtzqwY4QQn',
  },
  1486209: {
    name: 'Екатеринбург',
    link: 'https://gravsport.com/',
    dateExpired: '2025-08-18',
    erid: '2VtzquYNM6W',
  },
  1496153: {
    name: 'Омск',
    link: 'https://gravsport.com/',
    dateExpired: '2025-07-21',
    erid: '2VtzqxMQJEz',
  },
  // 472045: {
  //   name: 'Воронеж',
  //   link: 'https://gravsport.com/',
  // },
  472757: {
    name: 'Волгоград',
    link: 'https://gravsport.com/',
    dateExpired: '2025-09-22',
    erid: '2VtzqueMf3C',
  },
  // 527579: {
  //   name: 'Менделеевск',
  //   link: 'https://gravsport.com/',
  // },
};

function Engraving() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const selectedCity = useSelector(getSelectedCity)
  const [isOpenPartnerModal, setIsOpenPartnerModal] = useState(false)

  if (!links.hasOwnProperty(selectedCity?.city?.id)) return null
  
  const currentDate = new Date()
  const expirationDate = new Date(links[selectedCity?.city?.id].dateExpired)
  
  if (currentDate > expirationDate) return null

  return (
    <section className={styles.engraving}>
      <div className={styles.inner}>
        <div className={styles.imgWrap}>
          {isMobile ? (
            <img className={styles.img} src={engravingImgMobi} width={250} height={210} alt="" />
          ):(
            <img className={styles.img} src={engravingImg} width={572} height={201} alt="" />
          )}

        </div>

        <div className={styles.content}>
          <div className={styles.titleWrap}>
            <p className={styles.title}>
              Закажи гравировку на жетоне финишера
            </p>
          </div>

          <a className={styles.link} href={links[selectedCity?.city.id].link} target="_blank" rel="noreferrer noopener">
            {isMobile ? 'Оформить со скидкой 20%' : 'Оформить предзаказ со скидкой 20%'}
          </a>
        </div>

        <button className={styles.partnerBtn} onClick={() => setIsOpenPartnerModal(true)}>
          <span className="visually-hidden">Дополнительная информация</span>
        </button>
      </div>

      {isOpenPartnerModal && (
        <Popup outsideStyles={styles} handleStatePopup={setIsOpenPartnerModal}>
          <p>ООО «Арена Плюс»</p>
          <p>ИНН: 7606117641</p>
          <p>ERID: {links[selectedCity?.city.id].erid}</p>
        </Popup>
      )}
    </section>
  )
}

export default Engraving
